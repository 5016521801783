<template>
  <div class="profile-card">
    <template v-if="getType(profile) === 'profile'">
      <badge
        v-if="profile.performer.data.online"
        class="position-absolute mt-2 ml-2 text-white bg-gradient-success status-badge"
      >{{getTrans('messages.online')}}</badge>
      <badge
        v-else
        class="position-absolute mt-2 ml-2 status-badge"
        type="secondary"
      >{{getTrans('messages.offline')}}</badge>
    </template>
    <div class="profile-card__img-wrapper">
      <badge
        v-if="profile.performer.data.online"
        type="secondary"
        class="status-badge position-absolute badge-viewer"
      >
        <i class="fa fa-eye"></i>
        {{profile.performer.data.viewer_count}}
      </badge>
      <img
        v-if="getType(profile) === 'profile'"
        class="profile-card__img"
        :class="[profile.performer.data.online ? '': 'grayscale']"
        :src="getImgUrl(profile)"
        @error="errorAvatar"
      />
      <i class="fa fa-link fa-3x profile-thumbnail__icon rounded-circle" v-else></i>
    </div>
    <div class="profile-card__info">
      <h5 class="profile-card__title">{{name}}</h5>
      <img
        class="profile-card__info-logo"
        :src="platformLogo(profilePlatformId(profile))"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import ProfileMixin from "@/mixins/Profile/ProfileMixin";
import ProfileImg from '@/views/Profiles/Composables/ProfileImg';
import PlatformMixin from "@/mixins/PlatformMixin";

export default {
  name: "profile-card",
  mixins: [PlatformMixin, ProfileMixin],
  components: {
  },
  props: {
    profile: Object
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('Camsite', ['getCamsite']),
    ...mapGetters('User', ['getUser']),
    ...mapGetters('Profile', ['getType']),
    name() {
      if (this.getType(this.profile) === 'profile') {
        return this.profile.performer.data.name;
      } else {
        return this.profile.title;
      }
    }
  },
  setup() {
    const { getImgUrl } = ProfileImg();
    return {
      getImgUrl
    };
  }
};
</script>
