<template>
  <div class="profiles-card-grid">
    <div
      v-for="profile in profiles"
      :key="profile.id"
    >
      <diva-selectable-card @click="profileClicked(profile)" :selected="selectedProfiles[profile.id] !== undefined">
        <profile-card :profile="profile"></profile-card>
      </diva-selectable-card>
    </div>
  </div>
</template>

<script>

import DivaSelectableCard from "@/components/Diva/DivaSelectableCard";
import ProfileCard from '@/views/Profiles/Components/ProfileCard';

export default {
  name: 'ProfilesCardList',
  emits: ['update:selected'],
  components: {
    DivaSelectableCard,
    ProfileCard
  },
  props: {
    profiles: {
      type: Array,
      required: true
    },
    selected: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selectLimit: {
      type: Number,
      default: null
    }
  },
  computed: {
    selectedProfiles() {
      return {...this.selected};
    },
  },
  methods: {
    profileClicked(profile) {
      let selected = {};
      if (this.selectLimit === 1) {
        selected[profile.id] = profile;
      } else {
        selected = this.selectedProfiles;
        if (selected[profile.id]) {
          delete selected[profile.id];
        } else if (!this.selectLimit || Object.keys(this.selectedProfiles).length < this.selectLimit) {
          selected[profile.id] = profile;
        }
      }
      this.$emit('update:selected', selected);
    },
  }
}
</script>

<style scoped>

</style>
